body {
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background: #eef5f4;
  /*font-size: 0.9rem;*/
  background-image: url("./assets/images/bg_logo.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 80px;
}

body.authenticated {
  background: white;
  background-image: unset;
}

p {
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: #999;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.2s;
}

i,
span {
  display: inline-block;
}

h4 {
  margin-top: 0.5rem;
}

.mainContent {
  margin-top: 50px;
  padding: 15px;
  width: 100%;
}


.navbar {
  padding: 15px 10px;
  background: #fff;
  border: none;
  border-radius: 0;
  margin-bottom: 0;
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.navbar-text {
padding: 0;
font-size: 0.8rem;
}

.container {
  max-width: 100%;
  display: flex;
  align-items: stretch;
  padding: 0px;
}

.centerAbs {
  position: fixed;
  z-index: 100;
  left: 50%;
}

.centerAbs .alert {
  z-index: 100;
  left: -50%;
}

.loading {
  height: 2px;
  width: 100%;
  bottom: 0px;
  position: absolute;
  overflow: hidden;
}
.loading:before {
  display: block;
  position: relative;
  content: "";
  left: -200px;
  width: 200px;
  height: 2px;
  bottom: 0px;
  background-color: #2980b9;
  animation: loading 2s linear infinite;
}
@keyframes loading {
  from {
    left: 0;
    width: 0;
  }
  to {
    left: 40%;
    width: 60%;
  }
}

#loginCard {
  width: 600px;
  margin: 0 auto;
  margin-top: 45px;
  background-color: rgba(255, 255, 255, 0.63);
}

.btn:focus {
  outline: none !important;
  box-shadow: none;
}

#topbar {
  position: fixed;
  width: 100%;
  padding: 0px 5px;
  height: 50px;
  z-index: 500;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

#topbar button {
  padding: 0;
  width: 36px;
  height: 36px;
  background-color: #fff !important;
  border: none;
  outline: none;
  text-align: center;
  vertical-align: top;
}

#topbar .topbar-account {
  margin: 0;
  color: #000;
}

#topbar svg {
  float: left;
  margin-right: 0.3rem;
  font-size: 2rem;
  padding-top: 0.7rem;
}

#topbar .dropdown {
  margin-right: 1rem;
}

/* loading dots */
@keyframes blink {
  /**
     * At the start of the animation the dot
     * has an opacity of .2
     */
  0% {
    opacity: 0.2;
  }
  /**
     * At 20% the dot is fully visible and
     * then fades out slowly
     */
  20% {
    opacity: 1;
  }
  /**
     * Until it reaches an opacity of .2 and
     * the animation can start again
     */
  100% {
    opacity: 0.2;
  }
}

.pleasewait span {
  /**
     * Use the blink animation, which is defined above
     */
  animation-name: blink;
  /**
     * The animation should take 1.4 seconds
     */
  animation-duration: 1.4s;
  /**
     * It will repeat itself forever
     */
  animation-iteration-count: infinite;
  /**
     * This makes sure that the starting style (opacity: .2)
     * of the animation is applied before the animation starts.
     * Otherwise we would see a short flash or would have
     * to set the default styling of the dots to the same
     * as the animation. Same applies for the ending styles.
     */
  animation-fill-mode: both;
}

.pleasewait span:nth-child(2) {
  /**
     * Starts the animation of the third dot
     * with a delay of .2s, otherwise all dots
     * would animate at the same time
     */
  animation-delay: 0.2s;
}

.pleasewait span:nth-child(3) {
  /**
     * Starts the animation of the third dot
     * with a delay of .4s, otherwise all dots
     * would animate at the same time
     */
  animation-delay: 0.4s;
}

/* Primary button override */
.btn-primary {
  color: #fff;
  background-color: #f99e5b;
  border-color: #f99e5b;
}
.btn-primary:focus {
  color: #fff;
  background-color: #f99e5b;
  border-color: #f99e5b;
}
.btn-primary:hover {
  color: #fff;
  background-color: #d17632;
  border-color: #d17632;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #f99e5b;
  border-color: #f99e5b;
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #999;
  border-color: #999;
}

.arrow-download:hover {
  color: #d17632;
}

.arrow-download {
  cursor: pointer;
  color: #f99e5b;
  -webkit-animation: mover 0.5 infinite alternate;
  animation: mover 0.5s infinite alternate;
}


.arrow-download {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 0.5 infinite alternate;
}
@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

.btn.signInFlexiWan {
  display: flex;
  align-items: center;
  margin: auto;
  border: 2px solid gray;
  border-radius: 0;
  margin-top: 2px;
}

.btn.signInFlexiWan div {
  font-weight: bold;
  font-size: 0.75rem
}
